﻿$gridPadding: ( small: 1rem, medium: ( small: 3rem, normal: 6rem ) );

$gridPadding2: ( small: ( 1: 1rem, 2: 1rem, 3: 2rem, 4: 3rem ), medium: ( 1: 1.5rem, 2: 3rem, 3: 6rem, 4: 15rem ) );

$sides: 'top', 'right', 'bottom', 'left';

@import '../../node_modules/foundation-sites/scss/xy-grid/_xy-grid.scss';

@mixin expandableGrid($size, $breakpoint) {
    @for $i from 1 through $size - 1 {
        @for $j from 1 through $size - $i {
            $cellSize: xy-cell-size($i + $j);

            .#{$breakpoint}-expand-#{$j}.medium-#{$i} {
                @include xy-cell($cellSize);
                max-width: $cellSize;
            }
        }
    }
}

@mixin setSpacing2($level, $breakpoint) {
    $spacingSettings: map-get($gridPadding2, $breakpoint);

    .row-level-#{$level} {
        @each $side in $sides {
            @if $breakpoint != 'small' or ($side != 'left' and $side != 'right') {
                @each $size, $padding in $spacingSettings {
                    &.padding-#{$side}-#{$size} {
                        padding-#{$side}: $padding;

                        &.invert-padding {
                            padding-#{$side}: 0;
                            margin-#{$side}: -$padding;
                        }

                        @if($side == 'bottom' or $side == 'top') {
                            &.grid-bars {
                                padding-#{$side}: 0;
                                margin-#{$side}: $padding;
                            }
                        }
                    }
                }
            }
        }
    }

    .area-level-#{$level} {
        @each $side in $sides {

            @if $breakpoint != 'small' or ($side != 'left' and $side != 'right') {
                @each $size, $padding in $spacingSettings {
                    &.padding-#{$side}-#{$size} {
                        &.area-level-#{$level} {
                            padding-#{$side}: $padding;

                            &.invert-padding {
                                padding-#{$side}: 0;
                                margin-#{$side}: -$padding;
                            }

                            @if ($side == 'left') {
                                sticky {
                                    .sticking {
                                        &.released {
                                            left: ($padding + 1rem) !important;
                                        }
                                    }
                                }
                            }
                        }

                        &.extend-to-edge {
                            padding: 0;

                            > div {
                                padding-#{$side}: $padding;

                                &.invert-padding {
                                    padding-#{$side}: 0;
                                    margin-#{$side}: -$padding;
                                }

                                @if($side == 'bottom' or $side == 'top') {
                                    &.grid-bars {
                                        padding-#{$side}: 0;
                                        margin-#{$side}: $padding;
                                    }
                                }
                            }

                            &.invert-padding {
                                > div {
                                    padding-#{$side}: 0;
                                    margin-#{$side}: -$padding;
                                }

                                @if($side == 'bottom' or $side == 'top') {
                                    &.grid-bars {
                                        > div {
                                            padding-#{$side}: 0;
                                            margin-#{$side}: $padding;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    $gutterSize: if($breakpoint == 'small', $smallGutters, $mediumGutters);

    @each $side in $sides {
        @if $breakpoint != 'small' or ($side != 'left' and $side != 'right') {
            @each $size, $padding in $spacingSettings {
                .padding-#{$side}-#{$size} {
                    padding-#{$side}: $padding;
                }
            }
        }

        .border-#{$side}-1 {
            border-#{$side}: 2px solid $light-gray;

            &.grid-area {
                @if ($side == 'left' or $side == 'right') {
                    position: relative;

                    @if ($breakpoint == 'small') {
                        border-#{$side}-width: 0;
                    }
                    @else {
                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            height: 100%;
                            width: 2px;
                            border-#{$side}: 2px solid $light-gray;
                        }
                    }
                }

                @if ($side == 'left') {
                    @if ($breakpoint == 'small') {
                        border-top: 2px solid $light-gray;
                        padding-top: $gutterSize;
                        margin-top: $gutterSize;
                    }
                    @else {
                        border-top-width: 0;
                        padding-top: 0;
                        margin-top: 0;

                        &::before {
                            left: -#{$gutterSize + .0625rem};
                        }
                    }
                }

                @if ($side == 'right') {
                    @if ($breakpoint == 'small') {
                        border-bottom: 2px solid $light-gray;
                        padding-bottom: $gutterSize;
                        margin-bottom: $gutterSize;
                    }
                    @else {
                        border-bottom-width: 0;
                        margin-bottom: 0;
                        padding-bottom: 0;

                        &::before {
                            left: -#{$gutterSize + .0625rem};
                        }
                    }
                }
            }
        }
    }

    .grid-widget {
        @each $side in $sides {
            @each $size, $padding in $spacingSettings {
                &.padding-#{$side}-#{$size} {
                    padding-#{$side}: $padding;

                    &.invert-padding {
                        padding-#{$side}: 0;
                        margin-#{$side}: -$padding;
                    }
                }
            }

            &.border-#{$side}-1 {
                border-#{$side}: 2px solid $light-gray;
            }
        }
    }
}

@mixin setLargePadding($divisor: 1, $breakpointToUse: 'medium', $restrictDesktop: false) {
    $spacingSettings: map-get($gridPadding2, $breakpointToUse);

    @for $grid from 1 through 12 {
        &.large-#{$grid} {
            @each $side in $sides {
                @each $size, $padding in $spacingSettings {
                    &.padding-#{$side}-#{$size} {
                        @if($restrictDesktop and ($side == 'left' or $side == 'right')) {
                            padding-#{$side}: 0;
                        } @else {
                            padding-#{$side}: #{$padding/$divisor};
                        }
                    }
                }
            }
        }
    }
}

@each $breakpoint in $breakpoint-classes {
    @include breakpoint($breakpoint) {

        @each $side in $sides {
            .#{$breakpoint}-margin-#{$side}-0 {
                margin-#{$side}: 0;
            }

            .#{$breakpoint}-padding-#{$side}-0 {
                padding-#{$side}: 0;
            }

            @each $size, $margin in $margins {
                .#{$breakpoint}-margin-#{$side}-#{$size} {
                    margin-#{$side}: $margin;
                }
            }

            @each $size, $padding in $paddings {
                .#{$breakpoint}-padding-#{$side}-#{$size} {
                    padding-#{$side}: $padding;
                }
            }
        }
    }
}

html, body {
    overflow-x: hidden;
}

body {
    background-color: $black;
}

#content {
    overflow: hidden;
}

.grid {
    .grid-row, .grid-area {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &.background-positioned {
            background-size: auto;
        }
    }

    .grid-area {
        display: flex;
        flex-wrap: wrap;

        > div {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .vertically-center-cells {
        .grid-area {
            > div {
                display: flex;
                align-items: center;

                > div {
                    width: 100%;
                }
            }
        }
    }

    .border-label {
        height: 73px;
        width: 73px;
        position: absolute;
        border: 2px solid $grey-light;
        border-radius: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.75px;
        background-color: $black;
        margin-left: -37px;
        left: 50%;
    }

    .invert-padding {
        z-index: 2;
    }

    .row-level-2 {
        > .grid-container {
            padding: 0;
        }
    }

    @include setSpacing2(1, small);
    @include setSpacing2(2, small);

    .grid-container-outer {
        padding: 0;
        width: 100%;
    }

    .overlay-color {
        position: absolute;
        z-index: 1;
        opacity: .39;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        + div {
            z-index: 2;
            position: relative;
        }
    }

    .border-left-1, .border-top-1 {
        &.border-labeled {
            margin-top: 37px;
            padding-top: 37px;
        }

        .border-label {
            top: -37px;
            margin-left: -37px;
            left: 50%;
        }
    }

    .border-right-1, .border-bottom-1 {
        &.border-labeled {
            margin-bottom: 37px;
            padding-bottom: 37px;
        }

        .border-label {
            bottom: -37px;
        }
    }

    .group-container {
        position: relative;
    }

    .indented-list {
        margin-left: 1.75rem;
        font-size: 1rem;
    }

    .dark-bg {
        &.grid-area, .grid-area:not(.light-bg) {
            h1, h2, h3, h4, h5, h6, p, ul, ol {
                color: $white;
            }
        }
    }

    .area-container {
        &.full {
        }
    }

    .pad-left, .padding-row-left-1, .padding-row-left-2, .padding-cell-left-1, .padding-cell-left-2 {
        padding-left: 1rem !important;
    }

    .pad-right, .padding-row-right-1, .padding-row-right-2, .padding-cell-right-1, .padding-cell-right-2 {
        padding-right: 1rem !important;
    }

    .space-children {
        &:not(.is-sticky) > div, &.is-sticky .sticker > .content {
            > * {
                + * {
                    margin-top: 1rem;
                }
            }
        }
    }

    .full {
        > .is-sticky {
            .sticker {
                > .content {
                    padding: 0 rem-calc(map-get($grid-margin-gutters, small) / 2);
                }
            }
        }
    }

    .grid-row.halved-background {
        background-size: 100% 500px;
    }

    .section-slide-label {
        display: none;
    }

    .extend-background {
        position: relative;

        > div {
            position: relative;
            z-index: 2;
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: -$smallGutters;
            height: 100%;
            width: 100vw;
            background-color: inherit;
        }
    }

    .extend-background:first-child, .medium-1 + .extend-background {
        &::before {
            left: auto;
            right: -$smallGutters;
        }
    }

    .spread-background {
        width: calc(100% + #{rem-calc(map-get($grid-margin-gutters, small))});
        margin-left: -#{rem-calc(map-get($grid-margin-gutters, small) / 2)};
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 1;
        max-width: none !important;

        + div {
            position: relative;
            z-index: 2;
        }
    }

    .medium-4:first-child,
    .medium-1 + .medium-10,
    .medium-1 + .medium-8,
    .medium-1 + .medium-6,
    .medium-1 + .medium-5,
    .medium-1 + .medium-4 {
        .spread-background {
            right: -1rem;
            width: 100vw;
        }
    }

    .medium-4 + .medium-8,
    .medium-1 + .medium-4 + .medium-6,
    .medium-1 + .medium-6 + .medium-4,
    .medium-1 + .medium-5 + .medium-5 {
        .spread-background {
            left: 0;
            width: 100vw;
        }
    }

    .border-colored {
        &::before, > div, .border-label {
            border-color: inherit !important;
        }
    }
}

@include breakpoint(small only) {
    .grid {
        .extend-to-edge {
            margin: 0 -#{rem-calc(map-get($grid-margin-gutters, small) / 2)};
            min-width: calc(100% + #{rem-calc(map-get($grid-margin-gutters, small))});
        }
    }
}


@include breakpoint(medium) {
    .grid {
        @include setSpacing2(1, medium);
        @include setSpacing2(2, medium);

        .indented-list {
            margin-left: 3.25rem;
        }

        .area-container {
            &.full {
                $gutterSize: rem-calc(map-get($grid-margin-gutters, medium) / 2);
                margin-left: -$gutterSize;
                margin-right: -$gutterSize;
            }
        }

        .pad-left, .padding-row-left-1, .padding-cell-left-1 {
            padding-left: 3rem !important;
        }

        .pad-right, .padding-row-right-1, .padding-cell-right-1 {
            padding-right: 3rem !important;
        }

        .padding-row-left-2, .padding-cell-left-2 {
            padding-left: 6rem !important;
        }

        .padding-row-right-2, .padding-cell-right-2 {
            padding-right: 6rem !important;
        }

        .full {
            > .pad-left, .padding-row-left-1, .padding-cell-left-1 {
                padding-left: 4rem !important;
            }

            > .pad-right, .padding-row-right-1, .padding-cell-right-1 {
                padding-right: 4rem !important;
            }

            .padding-row-left-2, .padding-cell-left-2 {
                padding-left: 7rem !important;
            }

            .padding-row-right-2, .padding-cell-right-2 {
                padding-right: 7rem !important;
            }
        }

        .space-children {
            &:not(.is-sticky) > div, &.is-sticky .sticker > .content {
                > * {
                    + * {
                        margin-top: 3rem;
                    }
                }
            }
        }

        .grid-divider {
            &[class*="medium-"]::before {
                position: absolute;
                top: 0;
                left: -16.5px;
                height: 100%;
                width: 1px;
                margin: 0;
            }
        }

        .full {
            > .is-sticky {
                .sticker {
                    > .content {
                        padding: 0 0 0 rem-calc(map-get($grid-margin-gutters, medium) / 2);
                    }
                }
            }
        }

        .grid-row.halved-background {
            background-size: 50% 500px;
        }

        .section-slide-label {
            display: block;
            max-width: 78rem;
            margin: 0 auto;
            position: relative;

            a {
                position: absolute;
                display: inline-block;
                color: $white;
                transition: color .3s;
                left: 2.125rem + rem-calc(map-get($grid-margin-gutters, medium) / 2);
                bottom: 0;
                border-bottom: 1px solid $white;
                transform: rotateZ(-90deg);
                transform-origin: left bottom;
                z-index: 2;
                padding-right: 3.5rem;

                &:hover, &:focus {
                    color: $orange;
                    cursor: pointer;
                    text-decoration: none;
                }

                span {
                    padding: .5rem 1rem;
                    margin-right: 1rem;
                    border-right: 2px solid $white;
                    display: inline-block;
                    width: 50px;
                }
            }
        }

        .spread-background {
            width: calc(100% + #{rem-calc(map-get($grid-margin-gutters, medium))});
            margin-left: -#{rem-calc(map-get($grid-margin-gutters, medium) / 2)};
        }

        .extend-background {
            &::before {
                left: -$mediumGutters;
            }
        }

        .extend-background:first-child, .medium-1 + .extend-background {
            &::before {
                left: auto;
                right: -$mediumGutters;
            }
        }

        .grid-row {
            &[slideshow-slide] {
                position: fixed;
                bottom: 0;
                left: 0;
                z-index: 2;

                &:nth-child(1) {
                    z-index: 10;
                }

                &:nth-child(2) {
                    z-index: 9;
                }

                &:nth-child(3) {
                    z-index: 8;
                }

                &:nth-child(4) {
                    z-index: 7;
                }

                &:nth-child(5) {
                    z-index: 6;
                }

                &:nth-child(6) {
                    z-index: 5;
                }
            }
        }

        .grid-area {
            @include setLargePadding(2, 'medium');
        }

        .border-labeled {
            min-height: 105px;
        }

        .border-left-1 {
            .border-label {
                left: -2.3125rem - $mediumGutters;
                top: 50%;
                margin-top: -37px;
                margin-left: 0;
            }
        }
    }

    .slideshow-background {
        background-color: $blue;
    }

    .is-slideshow-slide {
        .slideshow-background-overlay {
            background-color: rgba(darken($blue, 20%), .8) !important;
        }
    }
}

@include breakpoint(medium only) {
    .grid {
        div[desktopcolumns] {
            .grid-area {
                @include setLargePadding(2, 'medium', true);

                &.border-left-1 {
                    border-left-width: 0;
                    border-top-width: 2px;
                    margin-top: 1.5rem;
                }

                &.border-right-1 {
                    border-right-width: 0;
                    border-bottom-width: 2px;
                    margin-bottom: 1.5rem;
                }
            }
        }
    }
}

@include breakpoint(large) {
    .grid {
        .large-1 + .extend-background {
            &::before {
                left: auto;
                right: -$mediumGutters;
            }
        }

        .grid-area {
            @include setLargePadding();
        }
    }
}

@include breakpoint($grid-container + 2 * $mediumGutters) {
    .grid-row:not(.full) {
        max-width: $grid-container - 2 * $mediumGutters;
        margin: 0 auto;

        > .grid-container {
            padding: 0;
        }
    }
}


$slightNarrow: $grid-container * 11 / 12 - ($mediumGutters * 2);

@media print, screen and (min-width: #{$slightNarrow}) {
    .slight-narrow {
        margin: 0 auto;
        width: $slightNarrow;
    }
}

@media print, screen and (min-width: $grid-container) {
    .grid {
        .extend-to-edge {
            &:first-child, &:last-child {
                > div {
                    min-width: calc(100% + 50vw - #{$grid-container / 2 - .5rem});
                }
            }

            &:first-child {
                > div {
                    position: relative;
                    left: calc(#{$grid-container / 2 - .5rem} - 50vw);
                }
            }

            > div {
                background-color: inherit;
            }
        }

        .spread-background {
            width: calc(50% + 50vw - #{$grid-container / 2 - .5rem});
        }

        .grid-area {
            &:first-child {
                .spread-background {
                    right: 100%;
                }
            }

            &.last-child {
                .spread-background {
                    left: 100%;
                }
            }
        }
    }

    .gutter-x {
        .extend-to-edge {
            &:first-child, &:last-child {
                > div {
                    min-width: calc(100% + 50vw - #{$grid-container / 2 + .5rem});
                }
            }

            &:first-child {
                > div {
                    position: relative;
                    left: calc(#{$grid-container / 2 + .5rem} - 50vw);
                }
            }
        }
    }
}

@media print, screen and (min-width: 78rem) {
    .grid {
        .section-slide-label {
            opacity: 0;
            visibility: visible;
        }
    }
}

@include breakpoint(large) {
    .grid {
        .grid-divider {
            &[class*="large-"]::before {
                position: absolute;
                top: 0;
                left: -16.5px;
                height: 100%;
                width: 1px;
                margin: 0;
            }
        }
    }

    .gutter-x {
        padding-left: 0;
        padding-right: 0;
    }

    .wide-grid-margin-x {
        margin: 0 -1.5rem;

        > .cell {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            margin-bottom: 3rem;
            width: calc(50% - 3rem) !important;
        }
    }
}

@include breakpoint(medium up) {
    @include expandableGrid($grid-columns, medium);
}

@include breakpoint(large up) {
    @include expandableGrid($grid-columns, large);
}