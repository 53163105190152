﻿//step 2
#d4b7e07c-92b7-40bc-dfd0-c256565e3b62 {
    display: flex;
    flex-wrap: wrap;

    .umbraco-forms-container {

        &:first-child {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .umbraco-forms-field {
        flex: 0 0 auto;
        width: calc(100% - #{2 * $smallGutters});
        margin: 0 #{$smallGutters};
    }

    .firstname, .lastname {
        width: calc(50% - #{2 * $smallGutters});
    }

    .city, .country {
        width: calc(60% - #{2 * $smallGutters});
    }

    .state, .zip {
        width: calc(40% - #{2 * $smallGutters});
    }

    @include breakpoint(medium) {
        .umbraco-forms-container {
            width: 219px;
            margin: 0;

            &:first-child {
                flex: 1 1 auto;
                width: calc(100% - 219px);
                padding-right: 2.5rem;
                max-width: none;
            }
        }

        .umbraco-forms-field {
            width: calc(100% - #{2 * $mediumGutters});
            margin: 0 #{$mediumGutters};
        }

        .firstname, .lastname {
            width: calc(50% - #{2 * $mediumGutters});
        }

        .city, .country {
            width: calc(60% - #{2 * $mediumGutters});
        }

        .state, .zip {
            width: calc(40% - #{2 * $mediumGutters});
        }
    }
}
