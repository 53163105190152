﻿.widget-feed {
    &.variant-dealers {
        .feed-item {
            margin-bottom: 2.5rem;
        }
    }
}

.dealer {
    font-size: 1rem;

    h5, address {
        margin-bottom: 1rem;
    }

    h5 {
        color: $yellow;
        margin-bottom: 1rem;
        text-transform: none;
    }

    h6 {
        margin-bottom: 0;
        text-transform: none;
    }

    address {
        margin-bottom: 1.25rem;
        font-style: normal;
    }

    .icon-link {
        text-transform: none;
        font-weight: 400;

        i {
            font-size: 1.25rem;
            margin-right: 1rem;

            &.fa-email {
                font-size: .875rem;
            }
        }
    }

    li + li {
        margin-top: .75rem;
    }

    &.with-count {
        position: relative;
        padding: .1875rem 0 0 4.625rem;

        .count {
            color: $black;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 1px;
            width: 34px;
            font-size: 1rem;
            height: 34px;
            font-weight: 700;
            position: absolute;
            left: 0;
            top: 0;
            background-color: $yellow;
            border-radius: 17px;
        }
    }

    .distance-plus-name {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        h6 {
            margin-left: 1rem;
            margin-top: .1875rem;
        }
    }

    &.active {
        background-color: rgba($yellow, .05);
    }

    .icon-link {
        align-items: flex-start;
        flex-wrap: wrap;
        i {
            position: relative;
            top: .125rem;
            &.fa-email {
                top: .375rem;
            }
        }
    }

    .highlighted-brands {
        flex: 0 0 100%;
        padding-left: 2.5625rem;
    }
}
