﻿.custom-radio {
    margin-bottom: 1.25rem;

    input {
        display: none;
    }

    label {
        position: relative;
        letter-spacing: 1.5px;
        display: inline-block;
        padding: .0625rem 0 0 2.25rem;
        margin: 0;
        line-height: 1.375rem;
        text-transform: uppercase;
        font-weight: 700;

        &:hover {
            cursor: pointer;
        }

        &::before, &::after {
            content: '';
            position: absolute;
            display: block;
            top: 50%;
        }

        &::before {
            vertical-align: middle;
            border: 1px solid $white;
            height: 20px;
            width: 20px;
            left: 0;
            margin-top: -10px;
        }

        &::after {
            content: '\f013';
            font-family: $iconFont;
            color: $yellow;
            margin-top: -.75rem;
            font-size: .625rem;
            opacity: 0;
            left: .3125rem;
            transition: opacity .3s;
        }
    }

    input:checked + label {
        &::before {
            border-color: $yellow;
        }

        &::after {
            opacity: 1;
        }
    }


    &.image-radio {
        label {
            height: 2.5rem;
            padding-left: 0;

            &::before, &::after {
                display: none;
            }
        }

        img {
            height: 100%;
            transition: opacity .3s;
            opacity: .4;
        }

        input:checked + label {
            img {
                opacity: 1;
            }
        }
    }
}

.light-background {
    .custom-radio {
        label {
            color: $black;

            &::before {
                border-color: $black;
            }
        }

        input:checked + label {
            &::before {
                border-color: $yellow;
            }
        }

        a {
            color: $yellow;

            &:hover {
                color: darken($yellow, 10%);
            }
        }
    }
}

.buttons {
    .custom-radio {
        label {
            padding: .875rem 1.5rem .8125rem;
            line-height: 1.625rem;
            text-align: center;
            display: inline-block;
            font-size: 1rem;
            font-weight: 700;
            border: 1px solid $white;
            transition: all .3s;

            &::before, &::after {
                display: none;
            }
        }

        input:checked + label, label:hover {
            border-color: $yellow;
            color: $yellow;
        }
    }
}