﻿&.variant-product {
    font-size: 1.25rem;

    .brand {
        margin-bottom: 1.5rem;

        img {
            height: 50px;
        }
    }

    h1 {
        text-transform: none;
        font-weight: 600;
        letter-spacing: .5px;
    }

    h5 {
        font-size: 1.125rem;
        margin-bottom: .5rem;
    }

    .block, .product-info .accordion {
        margin: 2.5rem 0 0;
    }

    .loader-wrap {
        padding: 0 39px;
    }

    .slide {
        text-align: center;

        .image-container {
            margin: 0 auto;
        }

        p {
            margin-top: 1.5rem;
            margin-bottom: 0;
        }
    }

    .product-image {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            max-height: 100%;
        }
    }

    .prev, .next {
        top: 50%;
        margin-top: -16px;
    }

    .zoomer {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    .buttons {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.25rem;
        margin-right: -1.25rem;
        margin-bottom: -1rem;
        /*width: 520px;*/
        max-width: 100%;

        a {
            margin: 0 1.25rem 1rem;
        }
    }

    .amazon-prompt {
        margin: 0 1.25rem 1rem;
        text-transform: uppercase;
        width: 100%;

        a {
            margin: 0;
            color: $yellow;

            &:hover {
                color: lighten($yellow, 10%);
            }
        }
    }

    .links {
        margin-bottom: 0;

        > li + li {
            margin-top: 1.25rem;
        }
    }

    .naked {
        .accordion-content {
            padding-top: 1rem;
            padding-left: 45px;

            a {
                color: $yellow;

                &:hover {
                    color: lighten($yellow, 10%);
                }
            }
        }
    }

    .reveal-container {
        margin-bottom: 1.5rem;
    }

    .reviews-widget {
        display: inline-block;
    }

    .wrap-unset{
        flex-wrap: unset;
    }
    /*.reviews {
        font-size: 1.125rem;
        a {
            color: $yellow;
            &:hover {
                color: lighten($yellow, 10%);
            }
        }
    }

    .ratings {
        display: flex;
        align-items: center;
        stars {
            margin-right: 1rem;
        }
    }*/
    @include breakpoint(small only) {
        .product-info {
            margin-top: 1.5rem;
        }
    }

    @include breakpoint(medium) {
        .multi-line {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -2px;

            .emphasize, .no-bullet {
                flex: 0 0 auto;
                margin: 0 2px;
            }
        }
    }

    @media screen and (min-width: 1121px) {
        .amazon-prompt {
            text-align: center;
        }
    }
}
